import { Flex, Button, Paragraph } from 'theme-ui';
import Loader from 'components/common/Loader';
import ThrottleAlert from './ThrottleAlert';

const FormButton = ({
  onClick,
  disabled,
  text,
  type = 'button',
  isSubmitting = false,
  submitError = '',
}) => {
  return (
    <Flex
      sx={{
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        marginTop: ['24px', '56px', '56px'],
      }}
    >
      {isSubmitting ? (
        <Loader />
      ) : (
        <Button
          variant="primary"
          onClick={onClick}
          disabled={disabled}
          type={type}
        >
          {text}
        </Button>
      )}
      {submitError && (
        <ThrottleAlert
          error={submitError}
          ifNotThrottle={
            <Flex
              sx={{
                backgroundColor: 'errorLight',
                color: 'error',
                padding: '10px 20px',
                marginTop: '20px',
                fontSize: [2, 2, 2],
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center',
                fontWeight: 'normal',
                gap: '16px',
              }}
            >
              <strong>There was a problem submitting your appointment</strong>
              {submitError?.graphQLErrors?.[0]?.extensions?.code ===
              'RECAPTCHA_ERROR'
                ? 'Please complete the reCAPTCHA check by clicking the checkbox marked "I\'m not a robot"'
                : submitError?.message && submitError?.message !== 'undefined'
                  ? submitError.message
                  : 'Please refresh the page and try again. If the problem persists, please contact the centre directly.'}
            </Flex>
          }
        />
      )}
    </Flex>
  );
};

export default FormButton;
